.ingredient-list-container {
  border: solid #AEAEAE 1px;
  border-radius: 8px;
  padding: 1em;
}

select, input {
  margin: 5px;
  border: 1px solid #AEAEAE;
  border-radius: 8px;
  background-color: #C2FFC2;
  padding: 5px 35px 5px 5px;
  font-size: 12px;
  height: 30px;
}

select {
  width: 20em;
}

input {
  width: 5em;
}